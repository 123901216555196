import { AxiosResponse } from "axios";
import { TextEntryPoint } from "src/types/textEntryPoints";
import { ApiWhatsAppEntryPoints } from ".";
import { HttpMethods } from "../httpMethods";
import { InvokeServiceArgs } from "../interfaces";
import { serviceCall } from "../service-call";
import { Controllers, MessagingEntryPointApiRoutes, GeneralRoutes } from "src/utils/constants";

export class WhatsAppEntryPointService implements ApiWhatsAppEntryPoints {
    async createWhatsAppEntryPoint(entryPoint: TextEntryPoint): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.WhatsAppEntryPoint, GeneralRoutes.Create, null, entryPoint);
    }

    async getAllWhatsAppEntryPoints(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.WhatsAppEntryPoint, MessagingEntryPointApiRoutes.AllEntryPoints);
    }

    async getWhatsAppEntryPointById(entryPointId: string): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.WhatsAppEntryPoint, '', `${entryPointId}`);
    }

    async updateWhatsAppEntryPoint(entryPoint: TextEntryPoint): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.WhatsAppEntryPoint, GeneralRoutes.Update, null, entryPoint);
    }

    async invokeServiceCall(httpMethod: HttpMethods, controller: string, route: string, urlParams: string = null, data: any = null): Promise<AxiosResponse> {
        const args: InvokeServiceArgs = {
            httpMethod: httpMethod,
            controller: controller,
            route: route,
            urlParams: urlParams,
            data: data
        };
        
        return serviceCall.invokeService(args);
    }
}