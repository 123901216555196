import { AxiosResponse } from "axios";
import { ApiEmailEntryPoints } from ".";
import { HttpMethods } from "../httpMethods";
import { InvokeServiceArgs } from "../interfaces";
import { serviceCall } from "../service-call";
import { CommonApiRoutes, Controllers, MessagingEntryPointApiRoutes, GeneralRoutes } from "src/utils/constants";
import { TextEntryPoint, TypeEnum } from "src/types/textEntryPoints";

export class EmailEntryPointService implements ApiEmailEntryPoints {
    async getAssignToData(type: TypeEnum): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.EmailEntryPoint, CommonApiRoutes.AssignedToData, `?type=${type}`);
    }

    async getEmailEntryPointById(entryPointId: string): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.EmailEntryPoint, '', `${entryPointId}`);
    }

    async createEmailEntryPoint(entryPoint: TextEntryPoint): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.EmailEntryPoint, GeneralRoutes.Create, null, entryPoint);
    }

    async updateEmailEntryPoint(entryPoint: TextEntryPoint): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Post, Controllers.EmailEntryPoint, GeneralRoutes.Update, null, entryPoint);
    }

    async getAllEmailEntryPoints(): Promise<AxiosResponse> {
        return this.invokeServiceCall(HttpMethods.Get, Controllers.EmailEntryPoint, MessagingEntryPointApiRoutes.AllEntryPoints);
    }

    async invokeServiceCall(httpMethod: HttpMethods, controller: string, route: string, urlParams: string = null, data: any = null): Promise<AxiosResponse> {
        const args: InvokeServiceArgs = {
            httpMethod: httpMethod,
            controller: controller,
            route: route,
            urlParams: urlParams,
            data: data
        };

        return serviceCall.invokeService(args);
    }
}