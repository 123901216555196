export interface SelectListItem {
	id: string;
	name: string;
}

export enum ChannelTypeEnum {
	Voice = '0' as any,
	Generic = '1' as any,
	Email = '2' as any,
	WhatsApp = '3' as any,
	Sms = '4' as any
}


export enum ChannelCategoryEnum {
	Voice = '0' as any,
	Generic = '1' as any,
	Text = '2' as any
}

export enum AssignedTypeEnum {
    Queue = 0,
	Flow = 1
}