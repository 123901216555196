import { AxiosResponse } from "axios";
import { WhatsAppEntryPointService } from "./whatsAppEntryPointService";
import { TextEntryPoint } from "src/types/textEntryPoints";

export interface ApiWhatsAppEntryPoints {
    createWhatsAppEntryPoint(entryPoint: TextEntryPoint): Promise<AxiosResponse>
    getAllWhatsAppEntryPoints(): Promise<AxiosResponse>
    getWhatsAppEntryPointById(entryPointId: string): Promise<AxiosResponse>
    updateWhatsAppEntryPoint(entryPoint: TextEntryPoint): Promise<AxiosResponse>
}

export const whatsAppEntryPointService = new WhatsAppEntryPointService();