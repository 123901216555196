import { AssignToDataItem } from './botApplicationInstance';
import { AssignedTypeEnum } from './shared';

export interface TextEntryPoint {
    id?: string;
    identifier: string;
    name: string;
    email?: string;
    queueId?: string;
    flowId?: string;
    assignedMediaName?: string;
    type?: TypeEnum;
    accessToken?: string,
    businessAccountId: string,
    phoneNumber?: string,
    phoneNumberId?: string,
    url?: string
}

export const TextEntryPointAssignedTypeToLabelMapping: Record<AssignedTypeEnum, string> = {
    [AssignedTypeEnum.Queue]: "TextEntryPoint.Queue",
    [AssignedTypeEnum.Flow]: "TextEntryPoint.Flow"
}

export interface EntryPointAssignments {
    queues: AssignToDataItem[];
    flows: AssignToDataItem[];
}

export enum TypeEnum {
    Email = 2,
    WhatsApp = 3
}